<template>
  <page-layout :show-settings-link="false">
    <template #right-side>
&nbsp;
    </template>
    <template v-if="currentStep && currentStep <= steps" #header-title>
      <div :class="$style.stepWrapper">
        <span :class="$style.currentStep">{{ currentStep }}</span>
        {{ $trlMessage('gs.step_from') }} {{ steps }}
      </div>
    </template>
    <template #content>
      <div class="layout__content" :class="$style.layout">
        <component :is="stepComponent" @change-step="changeStep" />
      </div>
    </template>
  </page-layout>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import PageLayout from '@/components/page-layout/page-layout.vue';
import * as Tada from '@/modules/tada';

export default {
  name: 'GettingStartedPage',
  components: { PageLayout },
  props: {
    step: {
      type: Number,
      required: true
    },
    steps: {
      type: Number,
      required: true
    },
    block: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentStep: this.step,
      currentBlock: this.block
    };
  },
  computed: {
    stepComponent() {
      const block = this.currentBlock;
      return defineAsyncComponent(() => import(`./steps/${block}.vue`));
    }
  },
  mounted() {
    Tada.enable();
  },
  methods: {
    changeStep({ step, block }) {
      if (step === null) {
        return (window.location.href = '/');
      }
      this.currentStep = step;
      this.currentBlock = block;
    }
  }
};
</script>

<style module lang="less">
@import '~@less/common/variables';
@import '~@less/common/mixins/font';

.stepWrapper {
  color: @whiteColor;
  padding-left: @grid-gutter-width / 2;
}
.currentStep {
  display: inline-flex;
  border: 1px solid @whiteColor;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  margin-right: 3px;
  .font_bold();
}

.layout {
  margin: @islandGap;
}
</style>

<i18n lang="json">{}</i18n>
