/**
 * 🎉
 * Сыпет конфетти на страницу
 */
import { StorageHelper } from '@/shared/lib/util/storage-helper';

const LS_KEY = 'show_tada';

export function enable() {
  StorageHelper.set(LS_KEY, 'true');
}

export function disable() {
  StorageHelper.remove(LS_KEY);
}

export function isEnabled() {
  return StorageHelper.get(LS_KEY) === 'true';
}

export function makeTada() {
  if (isEnabled()) {
    import('./tada-animation').then(() => {
      disable();
    });
  }
}
