import { AppHelper } from '@/app/entries/helpers/app-helper';

import store from '../../app/store';
import GettingStarted from './getting-started.vue';

export function mountGettingStarted(mountElement) {
  if (!mountElement) {
    return;
  }
  const { step, steps, block } = mountElement.dataset;
  const props = {
    step: Number.parseInt(step),
    steps: Number.parseInt(steps),
    block
  };

  AppHelper.createApp(GettingStarted, {
    store,
    selector: mountElement,
    props,
    route: {
      name: 'getting-started-page',
      params: props
    }
  });
}
